import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';

import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AnimatedComponent from './AnimatedComponent';

function Contact() {
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_lkzhq8f', 'template_a2817mq', form.current, {
            publicKey: 'H3axHrhILImg9bIpc',
        })
            .then(() => {
                toast.success('Email sent successfully!');
                form.current.reset(); // Reset form fields
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch((error) => {
                toast.error(`Failed to send email: ${error.text}`);
            });
    };

    return (
        <>
            <section className="contact" id="connect">
            <ToastContainer />
                <Container>
                    <Row className="align-items-center">
                        <Col size={12} md={6}>
                            <AnimatedComponent animationClass="animate__zoomIn">
                                <img src={contactImg} alt="Contact Us" />
                            </AnimatedComponent>
                        </Col>
                        <Col size={12} md={6}>
                            <AnimatedComponent animationClass="animate__fadeIn">
                                <h2>Get In Touch</h2>
                                <form ref={form} onSubmit={handleSubmit}>
                                    <Row>
                                        <Col size={12} lg={12} className="px-1">
                                            <input type="text" name='name' placeholder="Full Name" required />
                                        </Col>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="email" name='email' placeholder="Email Address" required />
                                        </Col>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="tel" name='phone' placeholder="Phone No." required />
                                        </Col>
                                        <Col size={12} className="px-1">
                                            <textarea rows="6" name='message' placeholder="Message" required></textarea>
                                            <button type="submit"><span>Submit</span></button>
                                        </Col>
                                    </Row>
                                </form>
                            </AnimatedComponent>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Contact;
