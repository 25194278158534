import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

function NavBar() {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false); // State to track navbar collapse

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
        // Close the navbar on mobile after clicking a link
        setExpanded(false);
    }

    return (
        <>
            <Router>
                <Navbar expand="lg" className={scrolled ? "scrolled" : ""} expanded={expanded}>
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={logo} alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                                <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
                            </Nav>
                            <span className="navbar-text">
                                <div className="social-icon">
                                    <a target="__blank" href="https://www.linkedin.com/in/krishna-chaudhary-967ba9277/"><img src={navIcon1} alt="LinkedIn" /></a>
                                    <a target="__blank" href="https://www.facebook.com/chaudhary.krishna.221"><img src={navIcon2} alt="Facebook" /></a>
                                    <a target="__blank" href="https://www.instagram.com/_chaudharykrishna__/"><img src={navIcon3} alt="Instagram" /></a>
                                </div>
                                <HashLink smooth to='#connect' onClick={() => setExpanded(false)}>
                                    <button className="vvd"><span>Let's Connect</span></button>
                                </HashLink>
                            </span>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Router>
        </>
    )
}

export default NavBar;
