import React from 'react';
import 'animate.css/animate.min.css';
import { useInView } from 'react-intersection-observer';

const AnimatedComponent = ({ animationClass, children }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.05,
    });

    return (
        <div
            ref={ref}
            className={inView ? `animate__animated ${animationClass}` : ''}
        >
            {children}
        </div>
    );
};

export default AnimatedComponent