import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsArrowRightCircle } from "react-icons/bs";
import headerImg from '../assets/img/header-img.svg'

import AnimatedComponent from './AnimatedComponent'


function Banner() {

    return (
        <>
            <section className="banner" id="home">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} xl={7}>
                            <AnimatedComponent animationClass="animate__fadeIn">

                                <span className="tagline">Welcome to my Portfolio</span>
                                <h1>Hi! I'm Krishna</h1>
                                <p>
                                    I'm proficient in HTML, CSS, JavaScript, and ReactJS and I also create dynamic and interactive user interfaces for websites and applications. I leverage ReactJS to build reusable UI components, ensuring efficient and scalable frontend development. Collaborating with teams, I strive to deliver engaging digital experiences that meet both design and functional requirements.
                                </p>
                                <button onClick={() => {
                                    const contactSection = document.getElementById('connect');
                                    if (contactSection) {
                                        contactSection.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                    Let's Connect <BsArrowRightCircle size={25} />
                                </button>

                                {/* <button scroll to onClick={() => console.log('connect')}>Let's Connect <BsArrowRightCircle size={25} /></button> */}
                            </AnimatedComponent>

                        </Col>
                        <Col xs={12} md={6} xl={5}>
                            <AnimatedComponent animationClass="animate__zoomIn">
                                <img src={headerImg} alt="Header Img" />
                            </AnimatedComponent>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Banner
