import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import colorSharp from "../assets/img/color-sharp.png"
import AnimatedComponent from './AnimatedComponent';


function Skills() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <section className='skill' id='skills'>
                <Container>
                    <Row>
                        <Col>
                            <div className="skill-bx">
                                <AnimatedComponent animationClass="animate__fadeIn">
                                    <h2>Skills</h2>
                                    <p>A frontend developer should excel in HTML, CSS, JavaScript frameworks like React, and UX principles to create responsive, user-friendly web applications.</p>
                                </AnimatedComponent>
                                <AnimatedComponent animationClass="animate__slideInUp">
                                    <Carousel responsive={responsive} infinite={true} className='skill-slider'>
                                        <div className="item">
                                            <img src={meter1} alt="Image1" />
                                            <h5>Web Development</h5>
                                        </div>
                                        <div className="item">
                                            <img src={meter2} alt="Image2" />
                                            <h5>Brand Identity</h5>
                                        </div>
                                        <div className="item">
                                            <img src={meter3} alt="Image3" />
                                            <h5>Logo Design</h5>
                                        </div>
                                    </Carousel>
                                </AnimatedComponent>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img src={colorSharp} alt="background" className="background-image-left" />
            </section>
        </>
    )
}

export default Skills
