import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from './ProjectCard';
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';

import AnimatedComponent from './AnimatedComponent';

function Projects() {

    const projects = [
        {
            title: "Somarjun Pratisthan",
            description: "Investment Company",
            imgUrl: projImg1,
        },
        {
            title: "Kundalini Travel Nepal",
            description: "Travel & Tourism",
            imgUrl: projImg2,
        },
        {
            title: "Disney Sports",
            description: "Sports & Fitness",
            imgUrl: projImg3,
        },
        {
            title: "Text-App",
            description: "Modification of text",
            imgUrl: projImg4,
        },
        {
            title: "NewsMonkey-App",
            description: "Latest News online about different categories",
            imgUrl: projImg5,
        },
        {
            title: "Todo List-App",
            description: "Marking the important events",
            imgUrl: projImg6,
        },
    ];

    return (
        <>
            <section className="project" id="projects">
                <Container>
                    <Row>
                        <Col size={12}>
                            <AnimatedComponent animationClass="animate__fadeIn">
                                <h2>Projects</h2>
                                <p>These are the projects developed using HTML, CSS, JS and ReactJs.</p>
                            </AnimatedComponent>


                            <AnimatedComponent animationClass="animate__slideInUp">
                                <Row>
                                    {
                                        projects.map((project, index) => {
                                            return (
                                                <ProjectCard
                                                    key={index}
                                                    {...project}
                                                />
                                            )
                                        })
                                    }
                                </Row>
                            </AnimatedComponent>

                        </Col>
                    </Row>
                </Container>
                <img className="background-image-right" src={colorSharp2} alt='back' />
            </section>
        </>
    )
}

export default Projects
